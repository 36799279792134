<template>
  <div class="col-12 col-md-6 text-left welcomeText">
    <h1>Reset Password anda</h1>
    <h5>
      Masukkan password baru beserta kode OTP yang telah kami kirimkan ke nomor
      {{ telepon }}
    </h5>
    <form>
      <div class="form-group mt-3">
        <label class="text-white" for="exampleFormControlSelect1"
          >Password</label
        >
        <input
          type="password"
          class="form-control"
          :class="invalid.password"
          placeholder="Password"
          ref="password"
          v-model="password"
        />
        <small class="text-danger">{{ textError.password }}</small>
      </div>
      <div class="form-group mt-3">
        <label class="text-white" for="exampleFormControlSelect1"
          >Konfirmasi Password</label
        >
        <input
          type="password"
          class="form-control"
          placeholder="Konfirmasi Password"
          ref="konpassword"
          :class="invalid.konpassword"
          v-model="Konpassword"
        />
        <small class="text-danger">{{ textError.konpassword }}</small>
      </div>
      <div class="form-group mt-3">
        <label class="text-white" for="exampleFormControlSelect1"
          >Kode OTP</label
        >
        <div class="input-group">
          <input
            type=""
            class="form-control mb-0"
            :class="invalid.kodeVer"
            ref="kodever"
            aria-describedby=""
            placeholder="Masukan Kode"
            v-model="kodeVer"
          />
        </div>
        <small class="text-danger">{{ textError.kodeVer }}</small>
      </div>
      <button
        type="submit"
        class="btn btn-light mt-1"
        style="background-color: rgba(255, 255, 255, 0.5); color: white"
        @click.prevent="resetPassword"
      >
        <template v-if="loading">
          <div
            class="spinner-border spinner-border-sm text-primary"
            role="status"
          >
            <span class="sr-only"></span>
          </div>
          <span class="span-loading">Loading</span>
        </template>
        <h5 class="mb-0" v-if="!loading">Reset Password</h5>
      </button>
    </form>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      loading: false,
      password: "",
      Konpassword: "",
      userName: "",
      kodeVer: "",
      telepon: "813328811",
      invalid: {
        password: "",
        konpassword: "",
        kodeVer: "",
      },
      textError: {
        password: "",
        konpassword: "",
        kodeVer: "",
      },
      isError: true,
    };
  },
  watch: {
    kodeVer(val) {
      if (val.length >= 4 && this.invalid.kodeVer) {
        this.invalid.kodeVer = "";
        this.textError.kodeVer = "";
      }
    },

    password(val) {
      if (val.length > 7) {
        this.invalid.password = "";
        this.textError.password = "";
        if (val != this.Konpassword) {
          this.textError.konpassword = "Harus sesuai dengan password";
        } else {
          this.invalid.password = "";
          this.textError.password = "";
          this.invalid.konpassword = "";
          this.textError.konpassword = "";
        }
      } else {
        this.textError.password = "Minimal 8 character";
      }
    },
    Konpassword(val) {
      if (val.length > 7 && val == this.password) {
        this.invalid.konpassword = "";
        this.textError.konpassword = "";
        this.invalid.password = "";
        this.textError.password = "";
      } else {
        this.textError.konpassword = "Harus sesuai dengan password";
      }
    },
  },
  methods: {
    checkPassword() {
      let focus_form;
      this.isError = false;
      if (this.kodeVer.length < 4) {
        focus_form = "kodever";
        this.invalid.kodeVer = "is-invalid";
        this.isError = true;
        this.textError.kodeVer =
          "Silahkan isi kode verifikasi / pastikan kode verifikasi benar";
      }

      if (this.Konpassword.length < 8) {
        focus_form = "konpassword";
        this.invalid.konpassword = "is-invalid";
        this.isError = true;
        this.textError.konpassword = "konfirmasi minimal 8 character";
      }

      if (this.password.length < 8) {
        focus_form = "password";
        this.invalid.password = "is-invalid";
        this.isError = true;
        this.textError.password = "konfirmasi minimal 8 character";
      } else {
        if (this.Konpassword != this.password) {
          focus_form = "password";
          this.invalid.konpassword = "is-invalid";
          this.invalid.password = "is-invalid";
          this.textError.konpassword = "Harus sesuai dengan password";
          this.isError = true;
        }
      }
      if (focus_form) this.$refs[focus_form].focus();
    },
    async resetPassword() {
      await this.checkPassword();
      if (this.isError) return;

      axios
        .post(`/api/reset-password`, {
          kodeOTP: this.kodeVer,
          username: this.userName,
          newPassword: this.password,
        })
        .then(({ data }) => {
          sessionStorage.removeItem("resetPas");
          this.$toast.success("Reset Password Berhasil");
          this.$router.push({ name: "LoginPage" });
        })
        .catch((err) => {
          let msg = err.response.data ? err.response.data.title : err.msg;
          this.$toast.error(msg);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    const sessionStorageItem = sessionStorage.resetPas;
    if (sessionStorageItem) {
      const item = JSON.parse(sessionStorageItem);
      this.userName = item.name;
      this.telepon = item.telepon;
    }
  },
};
</script>

<style>
</style>